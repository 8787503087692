import React from "react";
import { Col, Row } from "reactstrap";
import { gsap, ScrollTrigger } from "gsap/all";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";

const AdvantagesDetails = () => {

    React.useEffect(() => {
        let sections = gsap.utils.toArray(".panel");

        let scrollTween = gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none", // <-- IMPORTANT!
            scrollTrigger: {
                trigger: ".advantageScroll",
                pin: true,
                scrub: 0.1,
                //snap: directionalSnap(1 / (sections.length - 1)),
                end: "+=5000"
            }
        });
    }, []);

    return (
        <>
            <div className="" id="advantagesDetails">
                <div className="d-none d-lg-block">
                    <div className="advantageScroll">
                        <div className="panel">
                            <Row className="justify-content-end align-items-center min-height-100 mx-0">
                                <Col lg={4} md={4} sm={11} xs={11} className="position-relative">
                                    <div className="text-center text-lg-left advantageDetailsTitlePos">
                                        <span className="sectionTitle d-block text-center text-lg-left">
                                            Advantages of Gold Trading
                                        </span>
                                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />
                                    </div>
                                </Col>
                                <Col lg={1} md={1} sm={11} xs={11} />

                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip top duration={1500}>
                                        <div className="advantagesBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">Market Liquidity</span>
                                                <img src={require("../../assets/img/goldWave/advantage1.png").default} alt="" className="advantageImgSize" />
                                                <span className="sectionDesc d-block mt-5">Enjoy easy entry and exit with high trading volume, ensuring confidence in your trades.</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip bottom duration={1500}>
                                        <div className="advantagesBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">Global Influence</span>
                                                <img src={require("../../assets/img/goldWave/advantage2.png").default} alt="" className="advantageImgSize" />
                                                <span className="sectionDesc d-block mt-5">Leverage gold's responsiveness to global economic trends for diverse opportunities.</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                            </Row>
                        </div>

                        <div className="panel">
                            <Row className="justify-content-start align-items-center min-height-100 mx-0">
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip top duration={1500}>
                                        <div className="advantagesBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">Diverse Instruments</span>
                                                <img src={require("../../assets/img/goldWave/advantage3.png").default} alt="" className="advantageImgSize" />
                                                <span className="sectionDesc d-block mt-5">Choose from a variety of trading options, tailored to your strategy and risk appetite</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip bottom duration={1500}>
                                        <div className="advantagesBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">Historical Stability</span>
                                                <img src={require("../../assets/img/goldWave/advantage4.png").default} alt="" className="advantageImgSize" />
                                                <span className="sectionDesc d-block mt-5">Tap into centuries of wealth preservation, benefiting from gold's trusted stability.</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip top duration={1500}>
                                        <div className="advantagesBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">Central Bank Impact</span>
                                                <img src={require("../../assets/img/goldWave/advantage5.png").default} alt="" className="advantageImgSize" />
                                                <span className="sectionDesc d-block mt-5">Seize opportunities driven by central bank activities, guided by our expert insights.</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                            </Row>
                        </div>
                        <img src={require("../../assets/img/goldWave/gsapDeco.png").default} alt="" className="gsapDeco" />
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <Row className="justify-content-center align-items-center min-height-100 mx-0">
                        <Col lg={3} md={12} sm={11} xs={11} className="mb-5 pb-5">
                            <div className="text-center text-lg-left">
                                <span className="sectionTitle d-block text-center text-lg-left">
                                    Advantages of Gold Trading
                                </span>
                                <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />
                            </div>
                        </Col>

                        <Col lg={3} md={6} sm={11} xs={11} className="mb-4">
                            <Zoom duration={1500}>
                                <div className="advantagesBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">Market Liquidity</span>
                                        <img src={require("../../assets/img/goldWave/advantage1.png").default} alt="" className="advantageImgSize" />
                                        <span className="sectionDesc d-block mt-5">Enjoy easy entry and exit with high trading volume, ensuring confidence in your trades.</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={3} md={6} sm={11} xs={11} className="mb-4">
                            <Zoom duration={1500}>
                                <div className="advantagesBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">Global Influence</span>
                                        <img src={require("../../assets/img/goldWave/advantage2.png").default} alt="" className="advantageImgSize" />
                                        <span className="sectionDesc d-block mt-5">Leverage gold's responsiveness to global economic trends for diverse opportunities.</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>

                        <Col lg={3} md={6} sm={11} xs={11} className="mb-4">
                            <Zoom duration={1500}>
                                <div className="advantagesBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">Diverse Instruments</span>
                                        <img src={require("../../assets/img/goldWave/advantage3.png").default} alt="" className="advantageImgSize" />
                                        <span className="sectionDesc d-block mt-5">Choose from a variety of trading options, tailored to your strategy and risk appetite</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={3} md={6} sm={11} xs={11} className="mb-4">
                            <Zoom duration={1500}>
                                <div className="advantagesBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">Historical Stability</span>
                                        <img src={require("../../assets/img/goldWave/advantage4.png").default} alt="" className="advantageImgSize" />
                                        <span className="sectionDesc d-block mt-5">Tap into centuries of wealth preservation, benefiting from gold's trusted stability.</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={3} md={6} sm={11} xs={11} className="mb-4">
                            <Zoom duration={1500}>
                                <div className="advantagesBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">Central Bank Impact</span>
                                        <img src={require("../../assets/img/goldWave/advantage5.png").default} alt="" className="advantageImgSize" />
                                        <span className="sectionDesc d-block mt-5">Seize opportunities driven by central bank activities, guided by our expert insights.</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default AdvantagesDetails;
