import React from "react";
import { Col, Row } from "reactstrap";
import { FaTelegramPlane } from 'react-icons/fa';

const GoldWaveIntro = () => {

    return (
        <>
            <Row className="justify-content-center align-items-start mx-0 position-relative" id="goldWaveIntro">
                <Col lg={10} md={11} sm={12} xs={12} className="mb-5">
                    <Row className="justify-content-center align-items-center mx-0 w-100 position-relative goldWaveIntroDiv py-4">
                        <Col lg={3} md={7} sm={9} xs={9} className="my-5 my-lg-0">
                            <img src={require("../../assets/img/goldWave/goldBar.png").default} alt="" className="w-100" />
                        </Col>
                        <Col lg={9} md={12} sm={12} xs={12} className="mb-5 mb-lg-0 px-4 px-lg-5">
                            <span className="advantagesBoxTitle d-block text-center text-lg-left">AT QUANTBRIDGE,</span>
                            <span className="sectionDesc d-block mt-4 text-center text-lg-left">
                                We're committed to your gold trading success. Our strategies empower both
                                seasoned traders and newcomers, enhancing your journey towards unlocking gold trading's potential.
                            </span>

                            <div className="btnDiv2">
                                <a href="https://client.quantbridge.io/register" target="_blank">
                                    <div className="submitBtn text-center text-lg-left mt-5 mx-auto mx-lg-0 justify-content-center align-items-center">
                                        <span className="">
                                            Start Now
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default GoldWaveIntro;
