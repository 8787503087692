import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";

const TradingViewHeader = () => {

    return (
        <>
            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0" id="tradingViewHeader">
                <Col lg={6} md={11} sm={12} xs={12} className="headerPadding">
                    <Fade duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="headerTitle">
                                Trading View
                            </span>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </>
    );
};

export default TradingViewHeader;
