import React from "react";
import { Col, Row } from "reactstrap";
import Bounce from "react-reveal/Bounce";

const GoldWaveStrategy = () => {

    return (
        <>
            <div className="" id="goldWaveStrategy">
                <Row className="justify-content-center align-items-start mx-0 position-relative">
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-5 pb-4">
                        <div className="text-center">
                            <span className="sectionTitle d-block">
                                GoldWave Strategy:
                            </span>

                            <span className="sectionSmallTitle d-block">
                                Navigating Success with XAU/USD Trading
                            </span>

                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-4" />
                        </div>
                    </Col>

                    <Col lg={5} md={6} sm={12} xs={12} className="my-4">
                        <Bounce duration={1500}>
                            <Row className="justify-content-start align-items-start mx-0 position-relative">
                                <Col lg={2} md={2} sm={2} xs={2} className="">
                                    <div className="missionIndexText text-center">
                                        <img src={require("../../assets/img/trading-view/tradingPlatformSymbol.png").default} alt="" className="mx-auto tradingPlatformSymbol" />
                                    </div>
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                    <span className="goldWaveStrategyTitle d-block mb-2">
                                        Safe-Haven Approach
                                    </span>
                                    <span className="sectionDesc">
                                        Navigate market turbulence using the (XAU/USD) currency pair, capitalizing on the GoldWave Strategy for protection against volatility and economic downturns.
                                    </span>
                                </Col>
                            </Row>
                        </Bounce>
                    </Col>

                    <Col lg={5} md={6} sm={12} xs={12} className="my-4">
                        <Bounce duration={2000}>
                            <Row className="justify-content-start align-items-start mx-0 position-relative">
                                <Col lg={2} md={2} sm={2} xs={2} className="">
                                    <div className="missionIndexText text-center">
                                        <img src={require("../../assets/img/trading-view/tradingPlatformSymbol.png").default} alt="" className="mx-auto tradingPlatformSymbol" />
                                    </div>
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                    <span className="goldWaveStrategyTitle d-block mb-2">
                                        Trend Following
                                    </span>
                                    <span className="sectionDesc">
                                        Harness momentum through the GoldWave Strategy by tracking prevailing (XAU/USD) price trends, maximizing gains during upward movements.
                                    </span>
                                </Col>
                            </Row>
                        </Bounce>
                    </Col>

                    <Col lg={5} md={6} sm={11} xs={11} className="my-4">
                        <Bounce duration={1500}>
                            <Row className="justify-content-start align-items-start mx-0 position-relative">
                                <Col lg={2} md={2} sm={2} xs={2} className="">
                                    <div className="missionIndexText text-center">
                                        <img src={require("../../assets/img/trading-view/tradingPlatformSymbol.png").default} alt="" className="mx-auto tradingPlatformSymbol" />
                                    </div>
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                    <span className="goldWaveStrategyTitle d-block mb-2">
                                        Fundamental Analysis
                                    </span>
                                    <span className="sectionDesc">
                                        Predict shifts in both gold and currency (XAU/USD) prices with the GoldWave Strategy, leveraging economic factors for a competitive advantage.
                                    </span>
                                </Col>
                            </Row>
                        </Bounce>
                    </Col>

                    <Col lg={5} md={6} sm={11} xs={11} className="my-4">
                        <Bounce duration={2000}>
                            <Row className="justify-content-start align-items-start mx-0 position-relative">
                                <Col lg={2} md={2} sm={2} xs={2} className="">
                                    <div className="missionIndexText text-center">
                                        <img src={require("../../assets/img/trading-view/tradingPlatformSymbol.png").default} alt="" className="mx-auto tradingPlatformSymbol" />
                                    </div>
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                    <span className="goldWaveStrategyTitle d-block mb-2">
                                        Range Trading
                                    </span>
                                    <span className="sectionDesc">
                                        Utilize the GoldWave Strategy to profit from (XAU/USD) price oscillations in sideways markets, identifying predictable ranges for consistent gains.
                                    </span>
                                </Col>
                            </Row>
                        </Bounce>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default GoldWaveStrategy;
