import React from "react";
import { Col, Row } from "reactstrap";
import { gsap, ScrollTrigger } from "gsap/all";
import Fade from "react-reveal/Fade";

const ContactUsHeader = () => {

    React.useEffect(() => {
        gsap.to('.gsapScrollText1', {
            xPercent: 100,
            ease: "none",
            scrollTrigger: {
                trigger: ".gsapSection",
                start: "top left",
                end: "bottom left",
                scrub: true,
                end: "+=50%",
            }
        })

        gsap.to('.gsapScrollText2', {
            xPercent: -100,
            ease: "none",
            scrollTrigger: {
                trigger: ".gsapSection",
                start: "top left",
                end: "bottom left",
                scrub: true,
                end: "+=60%",
            }
        })
    }, [])

    return (
        <>
            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0" id="contactUsHeader">
                <Col lg={6} md={11} sm={12} xs={12} className="headerPadding">
                    <Fade duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="headerTitle">
                                Contact Us
                            </span>
                        </div>
                    </Fade>
                </Col>
            </Row>

            <div className="w-100 gsapSection">
                <div className="w-100">
                    <span className="gsapScrollText gsapScrollText1 d-block text-left">Connecting with Us</span>
                    <span className="gsapScrollText gsapScrollText2 d-block text-right">Your Pathway to Solutions</span>
                </div>
            </div>

        </>
    );
};

export default ContactUsHeader;
