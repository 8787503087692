import React from "react";
import { Col, Row } from "reactstrap";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import VisibilitySensor from "react-visibility-sensor";

const DataStats = () => {
    const needDominantBaselineFix = true;
    const [stats1, setStats1] = React.useState(0);
    const [stats2, setStats2] = React.useState(0);
    const [stats3, setStats3] = React.useState(0);
    const [stats4, setStats4] = React.useState(0);

    return (
        <>
            <VisibilitySensor
                onChange={(isVisible) => {
                    if(isVisible){
                        setStats1(100);
                        setStats2(92);
                        setStats3(98);
                        setStats4(89);
                    }
                }}
            >
                <Row className="justify-content-center align-items-center mx-0 position-relative" id="dataStats">
                    <Col lg={10} md={12} sm={12} xs={12} className="">
                        <Row className="justify-content-around align-items-start mx-0 position-relative py-5">
                            <Col lg={2} md={5} sm={6} xs={6} className="my-3 px-2 px-lg-2">
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={stats1}
                                    duration={2}
                                    easingFunction={easeQuadInOut}
                                >
                                    {(value) => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbarWithChildren
                                                value={value}
                                                strokeWidth={3}
                                                text={<tspan dy={needDominantBaselineFix ? -9 : 0}>{roundedValue}%</tspan>}
                                                styles={buildStyles({
                                                    pathColor: "#0884ff",
                                                    textColor: '#0884ff',
                                                })}
                                            >
                                                <span className="text-white sectionDesc" style={{ paddingTop: "6vh" }}>Transparency</span>
                                            </CircularProgressbarWithChildren>
                                        );
                                    }}
                                </AnimatedProgressProvider>
                            </Col>
                            <Col lg={2} md={5} sm={6} xs={6} className="my-3 px-2 px-lg-2">
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={stats2}
                                    duration={2}
                                    easingFunction={easeQuadInOut}
                                >
                                    {(value) => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbarWithChildren
                                                value={value}
                                                strokeWidth={3}
                                                text={<tspan dy={needDominantBaselineFix ? -9 : 0}>{roundedValue}%</tspan>}
                                                styles={buildStyles({
                                                    pathColor: "#0884ff",
                                                    textColor: '#0884ff',
                                                })}
                                            >
                                                <span className="text-white sectionDesc" style={{ paddingTop: "8vh" }}>Investment <br />Strategy</span>
                                            </CircularProgressbarWithChildren>
                                        );
                                    }}
                                </AnimatedProgressProvider>;
                            </Col>
                            <Col lg={2} md={5} sm={6} xs={6} className="my-3 px-2 px-lg-2">
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={stats3}
                                    duration={2}
                                    easingFunction={easeQuadInOut}
                                >
                                    {(value) => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbarWithChildren
                                                value={value}
                                                strokeWidth={3}
                                                text={<tspan dy={needDominantBaselineFix ? -9 : 0}>{roundedValue}%</tspan>}
                                                styles={buildStyles({
                                                    pathColor: "#0884ff",
                                                    textColor: '#0884ff',
                                                })}
                                            >
                                                <span className="text-white sectionDesc" style={{ paddingTop: "6vh" }}>Technology</span>
                                            </CircularProgressbarWithChildren>
                                        );
                                    }}
                                </AnimatedProgressProvider>;
                            </Col>
                            <Col lg={2} md={5} sm={6} xs={6} className="my-3 px-2 px-lg-2">
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={stats4}
                                    duration={2}
                                    easingFunction={easeQuadInOut}
                                >
                                    {(value) => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbarWithChildren
                                                value={value}
                                                strokeWidth={3}
                                                text={<tspan dy={needDominantBaselineFix ? -9 : 0}>{roundedValue}%</tspan>}
                                                styles={buildStyles({
                                                    pathColor: "#0884ff",
                                                    textColor: '#0884ff',
                                                })}
                                            >
                                                <span className="text-white sectionDesc" style={{ paddingTop: "7vh" }}>Risk<br />Management</span>
                                            </CircularProgressbarWithChildren>
                                        );
                                    }}
                                </AnimatedProgressProvider>;
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </VisibilitySensor>
        </>
    );
};

export default DataStats;
