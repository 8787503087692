import React from "react";
import { Col, Row } from "reactstrap";
import { gsap, ScrollTrigger } from "gsap/all";
import Fade from "react-reveal/Fade";

const OurCareerHeader = () => {

    React.useEffect(() => {
        gsap.to('.gsapScrollText1', {
            xPercent: 100,
            ease: "none",
            scrollTrigger: {
                trigger: ".gsapSection",
                start: "top left",
                end: "bottom left",
                scrub: true,
                end: "+=50%",
            }
        })

        gsap.to('.gsapScrollText2', {
            xPercent: -100,
            ease: "none",
            scrollTrigger: {
                trigger: ".gsapSection",
                start: "top left",
                end: "bottom left",
                scrub: true,
                end: "+=60%",
            }
        })
    }, [])

    return (
        <>
            <Row className="justify-content-start align-items-center mx-0" id="ourCareerHeader">
                <Col lg={7} md={11} sm={12} xs={12} className="headerPadding">
                    <Fade duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="headerTitle">
                                Our Careers
                            </span>
                        </div>
                    </Fade>
                </Col>
            </Row>

            <div className="w-100 gsapSection">
                <div className="w-100">
                    <span className="gsapScrollText gsapScrollText1 d-block text-left">turning Dreams into Reality,</span>
                    <span className="gsapScrollText gsapScrollText2 d-block text-right">One Step at a Time.</span>
                </div>
            </div>

            <Row className="justify-content-center align-items-center mx-0 my-5" id="ourCareerIntro">
                <Col lg={9} md={10} sm={12} xs={12} className="text-center ourCareerIntroContent" >
                    <Fade top duration={1500}>
                        <span className="sectionSmallTitle d-block text-center">
                            JOIN QUANTBRIDGE:
                        </span>
                        <span className="sectionTitle d-block text-center">
                            YOUR PATH TO SUCCESS
                        </span>
                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 text-center mx-auto" />
                        <span className="sectionDesc d-block mb-4">
                            Calling all money magnets! At Quantbridge, you'll be part of an exceptional team, honing your trading skills and exploring cutting-edge technology. Working alongside financial market giants, our experienced leaders will mentor you, encouraging your growth and impact in the industry. Embrace this thrilling opportunity to thrive and succeed with Quantbridge. Your future awaits!
                        </span>
                    </Fade>
                </Col>

                <img src={require("../../assets/img/ourCareer/careerIntroBg.png").default} alt="" className="text-center mx-auto careerIntroBg" />
            </Row>

        </>
    );
};

export default OurCareerHeader;
