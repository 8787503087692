import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";

const ResourceHeader = () => {

    return (
        <>
            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0" id="resourceHeader">
                <Col lg={6} md={11} sm={12} xs={12} className="headerPadding">
                    <Fade duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="headerTitle">
                                Elevate Your Trading with MT5
                            </span>
                        </div>
                    </Fade>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center mx-0 resourceHeaderDesc">
                <Col lg={9} md={10} sm={11} xs={11}>
                    <span className="sectionDesc d-block text-center">Embrace the power of MT5 by QuantBridge for advanced tools, automated strategies, mobile access, and multi-asset diversity. Anticipate MT5 listing, ensuring legitimacy. Elevate your trading journey today.</span>
                    <div className="mt-5 btnDiv">
                        <a href="https://client.quantbridge.io/login" target="_blank">
                            <div className="submitBtn text-left mt-5 mx-auto justify-content-center align-items-center">
                                <span className="">
                                    Start Trading
                                </span>
                            </div>
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ResourceHeader;
