import React from "react";
import { Col, Row } from "reactstrap";
import { gsap, ScrollTrigger } from "gsap/all";
import Fade from "react-reveal/Fade";

const OurTeamHeader = () => {

    React.useEffect(() => {
        gsap.to('.gsapScrollText1', {
            xPercent: 100,
            ease: "none",
            scrollTrigger: {
                trigger: ".gsapSection",
                start: "top left",
                end: "bottom left",
                scrub: true,
                end: "+=50%",
            }
        })

        gsap.to('.gsapScrollText2', {
            xPercent: -100,
            ease: "none",
            scrollTrigger: {
                trigger: ".gsapSection",
                start: "top left",
                end: "bottom left",
                scrub: true,
                end: "+=60%",
            }
        })
    }, [])

    return (
        <>
            <Row className="justify-content-start align-items-center mx-0" id="ourTeamHeader">
                <Col lg={8} md={11} sm={12} xs={12} className="headerPadding">
                    <Fade duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="headerTitle">
                                United by Vision,
                                Driven by Excellence
                            </span>
                        </div>
                    </Fade>
                </Col>
            </Row>

            <div className="w-100 gsapSection">
                <div className="w-100">
                    <span className="gsapScrollText gsapScrollText1 d-block text-left">INVESTING IN THE</span>
                    <span className="gsapScrollText gsapScrollText2 d-block text-right">RIGHT TEAM</span>
                </div>
            </div>

            <Row className="justify-content-center align-items-center mx-0 ourTeamIntro">
                <Col lg={9} md={10} sm={12} xs={12} className="">
                    <span className="sectionDesc d-block mb-4">
                        We believe in the power of our exceptional team. Led by accomplished professionals from Switzerland, Netherlands, Spain, Singapore, and Canada, our collective resume boasts impressive directorial positions in prominent banks and successful investment departments.
                    </span>
                    <span className="sectionDesc d-block mb-4">
                        We carefully select highly experienced and qualified traders, prioritizing profitability, capability, and unwavering dedication to growing our client's wealth. Rigorous vetting ensures that client funds are managed with the utmost care and skill. Our traders excel in handling significant capital, capitalizing on market opportunities, adeptly managing risks, and consistently delivering superior returns.
                    </span>
                    <span className="sectionDesc d-block mb-4">
                        Integrity and personal responsibility are core values at Quantbridge, and we take our investors' trust seriously. Your capital is treated with the utmost care as if it were our own, and we maintain total transparency in handling your money. Expect exceptional customer service and personalized attention, keeping you informed about your investments every step of the way.
                    </span>
                </Col>
            </Row>

        </>
    );
};

export default OurTeamHeader;
