import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";

const OurStrategiesHeader = () => {

    return (
        <>
            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0" id="ourStrategiesHeader">
                <Col lg={6} md={11} sm={12} xs={12} className="ourStrategiesHeaderContent headerPadding">
                    <Fade duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="headerTitle">
                                Our Strategies
                            </span>
                        </div>

                        <div className="text-center text-lg-left mt-3">
                            <span className="headerDesc">
                                QuantBridge is your premier source for systematic trading, mathematical finance, and quantitative strategies. Our focus is boosting your trading profits and fostering a fulfilling career path with us.
                            </span>
                        </div>
                    </Fade>
                </Col>

                <div className="overlayLayer" />
            </Row>
        </>
    );
};

export default OurStrategiesHeader;
