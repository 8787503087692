import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const OurCareerAdvise = () => {

    return (
        <>
            <div id="ourCareerAdvise">
                <Row className="justify-content-end align-items-center mx-0">
                    <Col lg={5} md={11} sm={12} xs={12}>
                        <div className="text-center text-lg-left">
                            <Fade top duration={1500}>
                                <span className="sectionTitle d-block text-center text-lg-left">
                                    Where Your Career Thrives
                                </span>
                                <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />
                                <span className="sectionDesc d-block text-justify text-lg-justify mb-4">
                                    At Quantbridge, we heavily invest in our staff, fostering an atmosphere of openness, collaboration, focus, and shared commitment. We empower you with opportunities and responsibilities, encouraging independent and innovative thinking. Our adherence to strict values and principles underscores our unwavering commitment to our investors.
                                </span>
                                <span className="goldBoldText d-block text-justify text-lg-justify mb-4">
                                    As part of Quantbridge, you can anticipate an exhilarating career and rewarding experiences.
                                </span>
                                <span className="sectionDesc d-block text-justify text-lg-justify">
                                    Send your CV to our HR Department at <span className="blueText">‘email address’</span> and embark on your journey with us. Your future awaits!
                                </span>
                            </Fade>
                        </div>
                    </Col>
                    <Col lg={6} md={11} sm={12} xs={12} className="px-0">
                        <img src={require("../../assets/img/ourCareer/careerAdviseImg.png").default} alt="" className="d-block mx-auto w-100" />
                    </Col>
                </Row>

                <div className="" id="affiliateProgram">
                    <Row className="justify-content-center align-items-center mx-0">
                        <Col lg={10} md={11} sm={12} xs={12}>
                            <div className="text-left">
                                <Row className="justify-content-center align-items-center mx-0">
                                    <Col lg={11} md={12} sm={12} xs={12} className="text-center">
                                        <span className="sectionSmallTitle d-block text-center">
                                            QUANTBRIDGE AFFILIATE PROGRAM:
                                        </span>
                                        <span className="sectionTitle d-block text-center">
                                            GREAT MINDS, GREAT REWARDS
                                        </span>
                                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 mx-auto text-center" />
                                        <span className="sectionDesc d-block mt-5 text-center">
                                            As a rapidly growing fund management service provider, Quantbridge is delighted to offer our existing investors an exclusive opportunity through our Quantbridge Affiliate Program. We believe in creating win-win situations. It's easy - just refer friends and family who are as savvy as you, and when they avail of Quantbridge services, you'll unlock lucrative rewards! Join the program and reap the benefits of our shared success.
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className="justify-content-center align-items-center mx-0" id="affiliateProgramContent">
                    <Col lg={8} md={11} sm={12} xs={12}>
                        <Zoom duration={1500}>
                            <div className="affiliateProgramBox text-center">
                                <span className="affiliateProgramTitle d-block mb-4 mt-4">
                                    Earn Commissions for Referrals
                                </span>
                                <span className="sectionDesc d-block text-center mb-5">
                                    As a valued investor with Quantbridge, you have the opportunity to earn incentive payments by referring other potential investors to us. Whether it's friends or family, anyone interested can be referred. The more referrals you make, the greater your commissions. It's our way of expressing gratitude for your trust in us.
                                </span>
                                <span className="goldBoldText d-block text-center mb-5">
                                    To join our affiliate program, simply sign up through our user-friendly online portal.
                                </span>
                            </div>
                        </Zoom>

                        <div className="affiliateProgramBottom text-center">
                            <span className="blueText2 d-block text-center mb-5">
                                For more information or assistance, please don't hesitate to contact us. Your referrals make a significant impact, and we appreciate your continued support!
                            </span>
                            <div className="btnDiv">
                                <a href="/contact-us">
                                    <div className="contactUsBtn text-center d-flex mx-auto justify-content-center align-items-center">
                                        <span className="">
                                            Join Us
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default OurCareerAdvise;
