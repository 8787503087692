import React from "react";
import { Col, Row } from "reactstrap";
import Zoom from "react-reveal/Zoom";
import resource3_hover from "../../assets/img/resource/resource3.png";

const ResourceType = () => {

    function displayDetailsHover(index) {
        for (let i = 1; i < 6; i++) {
            var elementId = "hoverDesc" + i;
            var elementId2 = "resource" + i;

            if (i.toString() === index) {
                document.getElementById(elementId).style.display = "block";
                document.getElementById(elementId2).src = "/resource" + index.toString() + "_hover.png";
            }
            else {
                document.getElementById(elementId).style.display = "none";
                document.getElementById(elementId2).src = "/resource" + i.toString() + ".png";
            }
        }
    }

    return (
        <>
            <Row className="justify-content-center align-items-end mx-0" id="resourceType">
                <Col lg={5} md={10} sm={11} xs={11} className="text-center resourceTypeTitleMb">
                    <span className="sectionTitle d-block text-center">Advanced, Mobile, and Legitimate</span>
                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />
                </Col>

                <Col lg={11} md={10} sm={11} xs={11} className="text-center">
                    <div className="position-relative d-none d-lg-block">
                        <div className="position-relative">
                            <Row className="justify-content-center align-items-end mx-0 curveContent position-relative">
                                <Col lg={2} md={2} sm={11} xs={11} className="text-center">
                                    <img src={require("../../assets/img/resource/resource1.png").default} alt="" className="resourceImgSize resource1" onMouseEnter={() => displayDetailsHover("1")} id="resource1" />
                                </Col>
                                <Col lg={2} md={2} sm={11} xs={11} className="text-center">
                                    <img src={require("../../assets/img/resource/resource2.png").default} alt="" className="resourceImgSize resource2" onMouseEnter={() => displayDetailsHover("2")} id="resource2" />
                                </Col>
                                <Col lg={2} md={2} sm={11} xs={11} className="text-center">
                                    <img src={require("../../assets/img/resource/resource3_hover.png").default} alt="" className="resourceImgSize resource3" onMouseEnter={() => displayDetailsHover("3")} id="resource3" />
                                </Col>
                                <Col lg={2} md={2} sm={11} xs={11} className="text-center">
                                    <img src={require("../../assets/img/resource/resource4.png").default} alt="" className="resourceImgSize resource4" onMouseEnter={() => displayDetailsHover("4")} id="resource4" />
                                </Col>
                                <Col lg={2} md={2} sm={11} xs={11} className="text-center">
                                    <img src={require("../../assets/img/resource/resource5.png").default} alt="" className="resourceImgSize resource5" onMouseEnter={() => displayDetailsHover("5")} id="resource5" />
                                </Col>
                            </Row>

                            <div className="justify-content-center align-items-end mx-0 dottedLineDiv">
                                <img src={require("../../assets/img/resource/dottedLine.png").default} alt="" className="" />
                            </div>

                            <div className="hoverDescPos">
                                <div className="justify-content-center align-items-start mx-0 position-relative hoverInnerPos w-100">
                                    {/* <Col lg={10} md={10} sm={11} xs={11} className="text-center"> */}
                                    <div className="hoverDesc1 hoverResourceDiv text-left py-4 px-4" id="hoverDesc1">
                                        <span className="d-block hoverResourceTitle mb-2">Legitimacy Guaranteed</span>
                                        <span className="d-block hoverResourceDesc">With MT5 listing approaching, ensure authenticity and prevent fake transactions.</span>
                                    </div>

                                    <div className="hoverDesc2 hoverResourceDiv text-left py-4 px-4" id="hoverDesc2">
                                        <span className="d-block hoverResourceTitle mb-2">Diverse Instruments</span>
                                        <span className="d-block hoverResourceDesc">Explore multi-asset trading possibilities beyond forex.</span>
                                    </div>

                                    <div className="hoverDesc3 hoverResourceDiv text-center py-4 px-4" id="hoverDesc3">
                                        <span className="d-block hoverResourceTitle mb-2">Sophisticated Analysis</span>
                                        <span className="d-block hoverResourceDesc">Utilize advanced charting tools for precise market analysis.</span>
                                    </div>

                                    <div className="hoverDesc4 hoverResourceDiv text-right py-4 px-4" id="hoverDesc4">
                                        <span className="d-block hoverResourceTitle mb-2">Mobile Trading</span>
                                        <span className="d-block hoverResourceDesc">Seamlessly trade on-the-go using the MT5 mobile app.</span>
                                    </div>

                                    <div className="hoverDesc5 hoverResourceDiv text-right py-4 px-4" id="hoverDesc5">
                                        <span className="d-block hoverResourceTitle mb-2">Effortless Strategy</span>
                                        <span className="d-block hoverResourceDesc">Implement strategies effortlessly with automated trading via Expert Advisors.</span>
                                    </div>
                                    {/* </Col> */}
                                </div>
                            </div>
                        </div>

                        <div className="resourceTypeImgBg">
                            <Zoom duration={1500}>
                                <img src={require("../../assets/img/resource/resourceTypeImg2.png").default} alt="" className="" />
                            </Zoom>
                        </div>
                    </div>


                    <div className="position-relative d-block d-lg-none">
                        <div className="text-left mb-5">
                            <img src={require("../../assets/img/resource/resource1.png").default} alt="" className="resourceImgSize mb-4" />
                            <div className="hoverResourceDiv text-left py-4 px-4">
                                <span className="d-block hoverResourceTitle mb-2">Legitimacy Guaranteed</span>
                                <span className="d-block hoverResourceDesc">With MT5 listing approaching, ensure authenticity and prevent fake transactions.</span>
                            </div>
                        </div>

                        <div className="text-left mb-5">
                            <img src={require("../../assets/img/resource/resource2.png").default} alt="" className="resourceImgSize mb-4" />
                            <div className="hoverResourceDiv text-left py-4 px-4">
                                <span className="d-block hoverResourceTitle mb-2">Diverse Instruments</span>
                                <span className="d-block hoverResourceDesc">Explore multi-asset trading possibilities beyond forex.</span>
                            </div>
                        </div>

                        <div className="text-left mb-5">
                            <img src={require("../../assets/img/resource/resource3.png").default} alt="" className="resourceImgSize mb-4" />
                            <div className="hoverResourceDiv text-left py-4 px-4">
                                <span className="d-block hoverResourceTitle mb-2">Sophisticated Analysis</span>
                                <span className="d-block hoverResourceDesc">Utilize advanced charting tools for precise market analysis.</span>
                            </div>
                        </div>

                        <div className="text-left mb-5">
                            <img src={require("../../assets/img/resource/resource4.png").default} alt="" className="resourceImgSize mb-4" />
                            <div className="hoverResourceDiv text-left py-4 px-4">
                                <span className="d-block hoverResourceTitle mb-2">Mobile Trading</span>
                                <span className="d-block hoverResourceDesc">Seamlessly trade on-the-go using the MT5 mobile app.</span>
                            </div>
                        </div>

                        <div className="text-left mb-5">
                            <img src={require("../../assets/img/resource/resource5.png").default} alt="" className="resourceImgSize mb-4" />
                            <div className="hoverResourceDiv text-left py-4 px-4">
                                <span className="d-block hoverResourceTitle mb-2">Effortless Strategy</span>
                                <span className="d-block hoverResourceDesc">Implement strategies effortlessly with automated trading via Expert Advisors.</span>
                            </div>
                        </div>

                        <div className="">
                            <Zoom duration={1500}>
                                <img src={require("../../assets/img/resource/resourceTypeImg.png").default} alt="" className="" />
                            </Zoom>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ResourceType;
