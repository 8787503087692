import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit

import "assets/scss/public.scss";

import Home from "views/Home/Home";
import AboutUs from "views/About Us/AboutUs";
import OurPartner from "views/OurPartner/OurPartner";
import OurStrategies from "views/OurStrategies/OurStrategies";
import TradingView from "views/Trading View/TradingView";
import MarketDiversity from "views/Market Diversity/MarketDiversity";
import ContactUs from "views/Contact Us/ContactUs";
import Resource from "views/Resource/Resource";
import GoldWave from "views/Gold Wave/GoldWave";
import OurTeam from "views/Our Team/OurTeam";
import OurCareer from "views/Our Career/OurCareer";
import HowToInvest from "views/How To Invest/HowToInvest";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/home" render={(props) => <Home {...props} />} />
        <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
        <Route path="/our-partner" render={(props) => <OurPartner {...props} />} />
        <Route path="/our-strategies" render={(props) => <OurStrategies {...props} />} />
        <Route path="/trading-view" render={(props) => <TradingView {...props} />} />
        <Route path="/market-diversity" render={(props) => <MarketDiversity {...props} />} />
        <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
        <Route path="/resource" render={(props) => <Resource {...props} />} />
        <Route path="/gold-wave" render={(props) => <GoldWave {...props} />} />
        <Route path="/our-team" render={(props) => <OurTeam {...props} />} />
        <Route path="/our-career" render={(props) => <OurCareer {...props} />} />
        <Route path="/how-to-invest" render={(props) => <HowToInvest {...props} />} />

        <Redirect to="/home" />
        <Redirect from="/" to="/home" />

      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
