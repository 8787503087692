import React from "react";
import { Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clientImg from "../../assets/img/home/clientImg.png";
import clientImg2 from "../../assets/img/home/clientImg2.png";
import clientImg3 from "../../assets/img/home/clientImg3.png";
import clientImg4 from "../../assets/img/home/clientImg4.png";
import clientImg5 from "../../assets/img/home/clientImg5.png";
import clientImg6 from "../../assets/img/home/clientImg6.png";
import Zoom from "react-reveal/Zoom";

const clientFeedback = [
    { img: clientImg, name: "Michael Reynolds", comment: "My investments have flourished since joining forces with QuantBridge. Their professional and responsive team has made me feel exceptionally valued." },
    { img: clientImg2, name: "Sarah Thompson", comment: "QuantBridge has revolutionized my trading experience. Their unmatched expertise in strategies, coupled with an incredibly user-friendly platform, has been a game-changer." },
    { img: clientImg3, name: "Lisa Garcia", comment: "Utilizing QuantBridge's copy trading on MT5 simplified my trading approach. I now trade confidently, achieving consistent returns." },
    { img: clientImg4, name: "John Parker", comment: "QuantBridge's support and educational resources have been pivotal in my trading success. Their unwavering commitment to client growth is truly commendable." },
    { img: clientImg5, name: "Emma Stevens", comment: "I extend my heartfelt gratitude to QuantBridge for the invaluable opportunities they've provided. Their platform is a game-changer for aspiring traders." },
    { img: clientImg6, name: "Robert Lawson", comment: "QuantBridge has democratized investing, making it accessible and profitable. Their innovative tools and strategies have transformed me into a confident and prosperous trader." },
]

const OurClient = () => {

    const settings = {
        dots: true,
        infinite: true,
        lazyLoad: "ondemand",
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 3 : 1,
        swipe: true,
        centerMode: true,
        centerPadding: 0,
    };

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative py-5" id="ourClients">
                <Col lg={12} md={12} sm={12} xs={12} className="">
                    <div className="text-center">
                        <span className="sectionTitle d-block">
                            OUR CLIENT
                        </span>
                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />
                    </div>

                    <div className="text-center mt-5">
                        <Slider {...settings} >
                            {
                                clientFeedback.map((item, i) => (
                                    <Zoom duration={1500}>
                                        <div className="px-5 px-lg-0" key={i}>
                                            <img src={item.img} className="d-block mx-auto clientProfilePic" />
                                            <div className="position-relative text-center mt-5 ">
                                                <span className="clientName d-block mb-3">{item.name}</span>
                                                <span className="sectionDesc d-block">{item.comment}</span>
                                                <img src={require("../../assets/img/home/clientFeedbackBg1.png").default} alt="" className="clientFeedbackBg1" />
                                                <img src={require("../../assets/img/home/clientFeedbackBg2.png").default} alt="" className="clientFeedbackBg2" />
                                            </div>
                                        </div>
                                    </Zoom>
                                ))
                            }
                        </Slider>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default OurClient;
