import React from "react";
import { Col, Row } from "reactstrap";
import Bounce from "react-reveal/Bounce";

const Advantages = () => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="advantages">
                <Col lg={8} md={11} sm={12} xs={12} className="text-center">
                    <span className="sectionTitle d-block mb-4 text-center">Experience the GoldWave Advantage with QuantBridge</span>
                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 mx-auto" />
                    <span className="sectionDesc d-block mb-4 text-center">Unveil the power of the GoldWave Strategy as it illuminates the XAU/USD pairing, reflecting gold's USD value, with a history of stability and consistent growth. The GoldWave Strategy, deeply rooted in this pairing, offers a structured approach that leverages gold's enduring value for consistent gains. Step into a world where opportunity converges with timeless value at QuantBridge. We understand that navigating financial markets requires a strategic touch. Our empowering trading strategies, including the specialized GoldWave Strategy, synergize with the benefits of gold (XAU/USD) trading to enhance your potential for success.</span>
                </Col>

                <Col lg={12} md={12} sm={12} xs={12} className="text-center px-0">
                    <Bounce duration={1500}>
                        <div className="btnDiv">
                            <a href="https://client.quantbridge.io/register" target="_blank">
                                <img src={require("../../assets/img/goldWave/advantageImg.png").default} alt="" className="mx-auto w-100" />
                            </a>
                        </div>
                    </Bounce>
                </Col>

                <img src={require("../../assets/img/goldWave/advantageTitleBg.png").default} alt="" className="advantageDeco" />
            </Row>
        </>
    );
};

export default Advantages;
