import React from "react";
import { Col, Row } from "reactstrap";
import { FaTelegramPlane } from 'react-icons/fa';
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";

const StrategicPartners = () => {

    return (
        <>
            <div className="" id="strategicPartner">
                <Row className="justify-content-center align-items-start mx-0 position-relative strategicPartnerRow">
                    <Col lg={4} md={9} sm={12} xs={12} className="mb-5">
                        <div className="text-center text-lg-left">
                            <span className="sectionTitle d-block text-center text-lg-left">
                                Strategic
                            </span>
                            <span className="sectionTitle d-block text-center text-lg-left mb-3">
                                Partners
                            </span>
                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />
                            <img src={require("../../assets/img/home/strategicImg.png").default} alt="" className="mt-3 strategicImg" />

                            <div className="btnDiv2">
                                <a href="/our-partner">
                                    <div className="learnMoreBtn text-center text-lg-left mt-5 d-flex justify-content-center align-items-center mx-auto mx-lg-0 mr-lg-auto">
                                        <span className="mr-5">
                                            Discover
                                        </span>

                                        <div>
                                            <span className="">></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} md={11} sm={12} xs={12}>
                        <Fade bottom duration={3000}>
                            <div className="text-center text-lg-left">
                                <span className="sectionDesc d-block text-center text-lg-left">
                                    At Quantbridge, we are accountable and transparent in handling your investments. Our streamlined procedures enable our team to focus on generating income on your behalf. Trust us to deliver efficiency and results.
                                </span>
                            </div>
                        </Fade>

                        <Row className="justify-content-center justify-content-lg-end align-items-center mx-0 mt-5">
                            <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                                <Flip top duration={1500}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/home/strategic1.png").default} alt="" className="w-100" />
                                        <div className="strategicTextPos px-4">
                                            <span>Accountable</span>
                                        </div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                                <Flip bottom duration={2000}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/home/strategic2.png").default} alt="" className="w-100" />
                                        <div className="strategicTextPos px-4">
                                            <span>Transparent</span>
                                        </div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                                <Flip top duration={2500}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/home/strategic3.png").default} alt="" className="w-100" />
                                        <div className="strategicTextPos px-4">
                                            <span>Efficient</span>
                                        </div>
                                    </div>
                                </Flip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default StrategicPartners;
