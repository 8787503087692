import React from "react";
import { Col, Row } from "reactstrap";
import Zoom from "react-reveal/Zoom";

const OurStrategiesType = () => {

    return (
        <>
            <Row className="justify-content-center align-items-start mx-0" id="strategiesType">
                <Col lg={5} md={10} sm={12} xs={12} className="mb-5">
                    <Zoom duration={1500}>
                        <div className="strategyDiv position-relative w-100">
                            <Row className="justify-content-start align-items-center mx-0">
                                <Col lg={9} md={10} sm={12} xs={12}>
                                    <span className="strategyTitle text-left d-block mb-4">
                                        Quantitative Trading Expertise
                                    </span>

                                    <span className="sectionDesc text-justify d-block mb-5">
                                        At QuantBridge, we offer comprehensive trading management services, ensuring seamless execution of your strategies for optimal results.
                                    </span>
                                </Col>
                            </Row>

                            <div className="strategyImgPos">
                                <img src={require("../../assets/img/our-strategies/strategy1.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Zoom>
                </Col>

                <Col lg={5} md={10} sm={12} xs={12} className="mb-5">
                    <Zoom duration={2000}>
                        <div className="strategyDiv position-relative w-100">
                            <Row className="justify-content-start align-items-center mx-0">
                                <Col lg={9} md={10} sm={12} xs={12}>
                                    <span className="strategyTitle text-left d-block mb-4">
                                        Expert Fund Management
                                    </span>

                                    <span className="sectionDesc text-justify d-block mb-4">
                                        QuantBridge excels in expertly managing investment funds on behalf of our clients. Our dedicated team handles the daily operations and strategic decision-making to optimize the fund's investments.
                                    </span>
                                </Col>
                            </Row>

                            <div className="strategyImgPos">
                                <img src={require("../../assets/img/our-strategies/strategy2.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Zoom>
                </Col>

                <Col lg={5} md={10} sm={12} xs={12} className="mb-5">
                    <Zoom duration={2500}>
                        <div className="strategyDiv position-relative w-100">
                            <Row className="justify-content-start align-items-center mx-0">
                                <Col lg={9} md={10} sm={12} xs={12}>
                                    <span className="strategyTitle text-left d-block mb-5">
                                        Masterful Trading Management
                                    </span>

                                    <span className="sectionDesc text-justify d-block mb-4">
                                        QuantBridge offers impeccable trading management services to ensure clients' trading strategies are executed with precision and effectiveness.
                                    </span>
                                </Col>
                            </Row>

                            <div className="strategyImgPos">
                                <img src={require("../../assets/img/our-strategies/strategy3.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Zoom>
                </Col>

                <Col lg={5} md={10} sm={12} xs={12} className="mb-5">
                    <Zoom duration={3000}>
                        <div className="strategyDiv position-relative w-100">
                            <Row className="justify-content-start align-items-center mx-0">
                                <Col lg={9} md={10} sm={12} xs={12}>
                                    <span className="strategyTitle text-left d-block mb-4">
                                        Fundamental Analysis
                                    </span>

                                    <span className="sectionDesc text-justify d-block mb-4">
                                        Explore the essence of informed decision-making through fundamental analysis. Understand how interest rates, production, earnings, and GDP drive markets. Navigate financial complexities with confidence.
                                    </span>
                                </Col>
                            </Row>

                            <div className="strategyImgPos">
                                <img src={require("../../assets/img/our-strategies/strategy4.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Zoom>
                </Col>

                <Col md={12}>
                    <div className="btnDiv">
                        <a href="/how-to-invest">
                            <div className="learnMoreBtn text-left mt-5 mx-auto d-flex justify-content-center align-items-center">
                                <span className="">
                                    Learn More
                                </span>
                            </div>
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default OurStrategiesType;
