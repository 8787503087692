import React from "react";
import { FaEnvelope, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { Col, Row } from "reactstrap";

const DarkFooter = () => {

    return (
        <>
            <div className="footer">
                <Row className="justify-content-center align-items-center mx-0 footerPadding">
                    <Col lg={10} md={11} sm={12} xs={12}>
                        <Row className="justify-content-center justify-content-lg-between align-items-start mx-0">
                            <Col lg={6} md={11} sm={12} xs={12} className="mb-3">
                                <img src={require("../assets/img/logo/footerLogo.png").default} alt="" className="d-block footerLogoSize" />
                                <div className="mt-5">
                                    <span className="footerTitleText d-block mb-3">QUANTBRIDGE: THRIVING THROUGH PROPRIETARY TRADING</span>
                                    <span className="footerDescText d-block mb-3">We generate financial wealth for our investors, leveraging expertise and technology to gain a clear competitive edge. Trust Quantbridget for your financial prosperity.</span>
                                </div>
                            </Col>

                            <Col lg={4} md={11} sm={12} xs={12}>
                                <div className="d-flex justify-content-start justify-content-lg-end align-items-center">
                                    <a href="https://wa.me/message/6ONE3WZR7OKXH1" target="_blank">
                                        <div className="socialMediaIcon mx-2">
                                            <FaWhatsapp className="socialMediaSize" />
                                        </div>
                                    </a>
                                    <a href="https://t.me/quantbridge_official" target="_blank">
                                        <div className="socialMediaIcon mx-2">
                                            <FaTelegramPlane className="socialMediaSize" />
                                        </div>
                                    </a>
                                    <a href="https://t.me/quantbridge_group" target="_blank">
                                        <div className="socialMediaIcon mx-2">
                                            <FaTelegramPlane className="socialMediaSize" />
                                        </div>
                                    </a>
                                    <a href="mailto:support@quantbridge.io" target="_blank">
                                        <div className="socialMediaIcon mx-2">
                                            <FaEnvelope className="socialMediaSize" />
                                        </div>
                                    </a>
                                </div>

                                <div className="mt-5 text-left text-lg-right">
                                    <span className="footerTitleText d-block mb-3">REGIONAL RESTRICTIONS:</span>
                                    <span className="footerDescText d-block mb-3">We do not offer our services to residents of certain jurisdictions such as North Korea, Japan, United States and some other regions.</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center align-items-center mt-5 pt-5 mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                <span className="copyrightText">Copyright © 2023 Quantbridge Capital. All rights reserved.</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default DarkFooter;
