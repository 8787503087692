import React from "react";
import { Col, Row } from "reactstrap";

const CustomizedApproach = () => {

    return (
        <>
            <div className="position-relative" id="customizedApproach">
                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={10} md={11} sm={12} xs={12}>
                        <div className="text-left">
                            <Row className="justify-content-center justify-content-md-start align-items-center mx-0">
                                <Col lg={7} md={11} sm={11} xs={11} className="px-0">
                                    <span className="sectionTitle d-block text-left">
                                        CUSTOMIZED <br /> INVESTMENT APPROACH
                                    </span>
                                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4" />
                                </Col>
                            </Row>

                            <Row className="justify-content-start align-items-end mx-0 customizedApproachBox my-5">
                                <Col lg={6} md={12} sm={12} xs={12} className="">
                                    <span className="sectionDesc d-block text-justify">
                                        At Quantbridge, we focus on generating steady returns over the medium to long term, irrespective of market conditions. Our adaptability and patience allow us to stay ahead in ever-changing markets. We develop new investment strategies to suit any environment, providing access to specialized markets with promising rewards. Patience is our virtue, minimizing risks and maximizing returns for our valued clients. Trust in Quantbridge for a rewarding investment journey.
                                    </span>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="px-5 position-relative">
                                    <img src={require("../../assets/img/ourTeam/customizedApproachImg.png").default} alt="" className="customizedApproachImg" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <img src={require("../../assets/img/ourTeam/customizedApproachDeco.png").default} alt="" className="customizedApproachDeco" />
            </div>
        </>
    );
};

export default CustomizedApproach;
